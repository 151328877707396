class GrowthStageIndicator extends HTMLElement {
  static get observedAttributes() {
    return ['description', 'percentage', 'stage']
  }

  attributeChangedCallback(name, _, newValue) {
    const progressBar = this.shadowRoot.querySelector('#progressBar')
    if (name === 'description') {
      this.shadowRoot.querySelector('#description').textContent = newValue
    }
    if (name === 'percentage') {
      progressBar.style.setProperty('--value', newValue)
      progressBar.setAttribute('aria-valuenow', newValue)
    }
    if (name === 'stage') {
      progressBar.setAttribute('data-before', newValue)
    }
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })
    this.shadowRoot.innerHTML = `
      <style lang="scss" scoped>
        .growth-stage-indicator {
          border-radius: 8px;
          border: 1px solid #E6E9E6;
          background-color: #FFF;
          padding: 16px;
          display: flex;
          gap: 12px;
          align-items: center;

          h1 {
            color: #1A2B46;
            font-family: Rubik;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }

        div[role="progressbar"] {
          --bg: #D8F2DC;
          width: 50px;
          aspect-ratio: 1;
          border-radius: 50%;
          display: grid;
          background: radial-gradient(circle, var(--bg) 0%, var(--bg) calc(100% - 14px), #0000 calc(100% - 14px), #0000 100%);
          font-family: Rubik;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          text-transform: capitalize;
          color: #1A2B46;
        }

        div[role="progressbar"]::after {
          content:"";
          grid-area: 1/1;
          border-radius: 50%;
          padding: 5px;
          transform: rotate(90deg);
          background:
            linear-gradient(#2B8337 0 0) 0/calc((var(--value) - 75)*1px) 100%,
            linear-gradient(#39AF49 0 0) 0/calc((var(--value) - 50)*1px) 100%,
            linear-gradient(#62CC70 0 0) 0/calc((var(--value) - 25)*1px) 100%,
            #96DDA0;
          --_m: 
            conic-gradient(#000 calc(var(--value) * 1%), #0000 0),
            linear-gradient(#000 0 0) content-box;
          -webkit-mask: var(--_m);
                  mask: var(--_m);
          -webkit-mask-composite: source-out;
                  mask-composite: subtract;
        }

        div[role="progressbar"]::before {
          content: attr(data-before);
          text-transform: capitalize;
          grid-area: 1/1;
          margin: auto;
        }
      </style>
      <div class="growth-stage-indicator">
        <div
          id="progressBar"
          role="progressbar"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
          style="--value:0"
        ></div>
        <h1 id=description></h1>
      </div>
    `
  }
}

customElements.define('growth-stage-indicator', GrowthStageIndicator)
