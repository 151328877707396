<template>
  <div class="df-flex-sm df-flex-col sowing-summary">
    <h1 class="summary__title">
      {{ $t('SowingView.sowing_summary.summary') }}
    </h1>
    <v-skeleton-loader
      v-if="loading"
      height="204px"
      type="image"
      width="auto"
    />
    <div v-else class="df-flex-m df-flex-col summary__card">
      <div class="df-flex-l">
        <div class="df-flex-xm df-flex-col df-col-6-t">
          <h3>{{ $t('SowingView.sowing_summary.status') }}</h3>
          <sowing-item-status-chip :status="sowingInfos.status" />
        </div>
        <div class="df-flex-xm df-flex-col df-col-6-t df-col-6-m flex-grow-0">
          <h3>{{ $t('SowingView.sowing_summary.sown_area') }}</h3>
          <span v-if="sowingInfos.sown_area">
            {{ sowingInfos.sown_area.toFixed(2) }}
            {{ $unitMeasures['area'][$currentLanguage()] }}
          </span>
          <span v-else>---</span>
        </div>
      </div>
      <v-divider />
      <div class="df-flex-l">
        <div class="df-flex-xm df-flex-col df-col-6-t">
          <h3>{{ $t('SowingView.sowing_summary.sown_at') }}</h3>
          <span v-if="sowingInfos.sown_at">
            {{ sowingInfos.sown_at.formatDate() }}
          </span>
          <span v-else>---</span>
        </div>
        <div class="df-flex-xm df-flex-col df-col-6-t df-col-6-m flex-grow-0">
          <h3>{{ $t('SowingView.sowing_summary.estimated_harvest') }}</h3>
          <span v-if="sowingInfos.estimated_harvest_date">
            {{ sowingInfos.estimated_harvest_date.formatDate() }}
          </span>
          <span v-else>---</span>
        </div>
      </div>
      <v-divider />
      <div class="df-flex-xm df-flex-col">
        <h3>{{ $t('SowingView.sowing_summary.total_costs') }}</h3>
        <span>
          {{ $currency(sowingInfos.inputs.total_cost) }} •
          {{ sowingInfos.inputs.bags }}
          {{ $t('SowingView.sowing_summary.bags') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import SowingItemStatusChip from '@/modules/sowingsList/components/list/SowingItemStatusChip.vue'

export default {
  name: 'SowingViewSummary',

  components: {
    SowingItemStatusChip,
  },

  props: {
    loading: {
      required: true,
      type: Boolean,
    },
    sowingInfos: {
      required: true,
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
.sowing-summary {
  max-height: fit-content;

  .summary__title {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .summary__card {
    border: 1px solid #e6e9e6;
    border-radius: 8px;
    background-color: #fff;
    padding: 16px;

    h3 {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
</style>
