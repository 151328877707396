<template>
  <div class="spraying-list">
    <div class="df-flex-l df-flex-m-t list__header">
      <div class="df-col-2">
        <span>{{ $t('SowingView.spraying_list.ideal_date') }}</span>
      </div>
      <div class="df-col-2 df-col-3-t">
        <span>{{ $t('SowingView.spraying_list.type') }}</span>
      </div>
      <div class="df-col-2 header__price">
        <span>{{ $t('SowingView.spraying_list.price_per_area') }}</span>
      </div>
      <div class="df-col-3">
        <span>{{ $t('SowingView.spraying_list.product_dose') }}</span>
      </div>
      <div class="df-col-3 df-col-4-t"></div>
    </div>
    <div class="df-flex-l df-flex-col list__content">
      <div
        v-for="(spraying, index) in sprayings"
        :key="spraying.id"
        class="df-flex-l df-flex-col"
      >
        <spraying-list-item :spraying="spraying" />
        <v-divider v-if="shouldShowDivider(index)" />
      </div>
    </div>
  </div>
</template>

<script>
import SprayingListItem from '@/modules/sowingView/components/list/SprayingListItem.vue'

export default {
  name: 'SprayingList',

  components: {
    SprayingListItem,
  },

  props: {
    sprayings: {
      required: true,
      type: Array,
    },
  },

  methods: {
    shouldShowDivider(index) {
      return index !== this.sprayings.length - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.spraying-list {
  border: 1px solid #e6e9e6;
  border-radius: 8px;

  .list__header {
    padding: 16px;
    border-bottom: 1px solid #e6e9e6;

    @include d(m) {
      display: none;
    }
    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
    .header__price {
      @include d(t) {
        display: none;
      }
      @include d(m) {
        display: none;
      }
    }
  }
  .list__content {
    padding: 16px;

    @include d(m) {
      padding: 0px;
    }
  }
}
</style>
