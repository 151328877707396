<template>
  <div class="df-flex-sm df-flex-col sowing-field-infos">
    <div class="df-flex-none justify-space-between align-center">
      <h1 class="infos__title">
        {{ $t('SowingView.sowing_field_infos.about') }}
      </h1>
      <df-button depressed variant="text-green" @click="redirectToField">
        {{ $t('SowingView.sowing_field_infos.see_field') }}
      </df-button>
    </div>
    <v-skeleton-loader
      v-if="loading"
      height="204px"
      type="image"
      width="auto"
    />
    <div v-else class="infos__card">
      <div class="df-flex-xl df-flex-col">
        <div class="df-flex-none justify-space-between align-center">
          <h2>{{ sowingInfos.field.name }}</h2>
          <span>
            {{ sowingInfos.field.area }}
            {{ $unitMeasures['area'][$currentLanguage()] }}
          </span>
        </div>
        <without-item-card
          v-if="!sowingInfos.field.sowings.length"
          name="field-sowing"
          text="WithoutItemCard.field_sowing.text"
          title="WithoutItemCard.field_sowing.title"
        />
        <div v-else class="df-flex-sm df-flex-col">
          <h3>{{ $t('SowingView.sowing_field_infos.other_sowings') }}</h3>
          <div class="df-flex-l df-flex-col">
            <div
              v-for="(sowing, index) in sowingInfos.field.sowings"
              :key="sowing.id"
              class="df-flex-sm df-flex-col"
            >
              <span class="df-flex-sm align- center card__genetic">
                <font-awesome-icon icon="seedling" />
                {{ $t(sowing.crop_name) }} | {{ sowing.variety_name }}
              </span>
              <sowing-item-status-chip :status="sowing.status" />
              <v-divider
                v-if="
                  shouldShowDivider(index, sowingInfos.field.sowings.length)
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import SowingItemStatusChip from '@/modules/sowingsList/components/list/SowingItemStatusChip.vue'
import WithoutItemCard from '@/components/Banners/WithoutItemCard.vue'
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'SowingFieldInfos',

  components: {
    DfButton,
    SowingItemStatusChip,
    WithoutItemCard,
  },

  props: {
    loading: {
      required: true,
      type: Boolean,
    },
    sowingInfos: {
      required: true,
      type: Object,
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
  },

  methods: {
    redirectToField() {
      logEvent(events.sowingViewModule.SowingView.see_field)
      this.$router.push({
        path: '/plot',
        query: {
          plot_id: this.sowingInfos.field.id,
          fazenda_id: this.currentFarmId,
        },
      })
    },
    shouldShowDivider(index, sowingsLength) {
      return index !== sowingsLength - 1
    },
  },
}
</script>

<style lang="scss" scoped>
.sowing-field-infos {
  max-height: fit-content;

  .infos__title {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .infos__card {
    border: 1px solid #e6e9e6;
    border-radius: 8px;
    background-color: #fff;
    padding: 16px;

    h2 {
      overflow: hidden;
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    h3 {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
    .card__genetic {
      color: #1a2b46;
      font-family: 'Rubik';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
</style>
