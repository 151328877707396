<template>
  <div class="df-flex-sm df-flex-col sowing-growth-stage">
    <div class="df-flex-none justify-space-between align-center">
      <h1 class="stage__title">
        {{ $t('SowingView.sowing_growth_stage.growth_stage') }}
      </h1>
      <df-button
        depressed
        variant="text-green"
        :loading="loading"
        @click="editGrowthStage"
      >
        {{ $t('SowingView.sowing_growth_stage.edit') }}
      </df-button>
    </div>
    <v-skeleton-loader v-if="loading" height="84px" type="image" width="auto" />
    <growth-stage-indicator
      data-id="growth-stage-indicator"
      v-else
      :description="description"
      :percentage="percentage"
      :stage="stageText"
    />
    <growth-stage-modal
      v-if="isGrowthStageModalOpened"
      :crop-name="cropName"
      :growth-stage="stageText"
      :sowing-id="sowingId"
      @close="closeGrowthStageModal"
    />
  </div>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import growthStage from '@/services/growthStage.js'
const GrowthStageModal = () =>
  import('@/modules/sowingView/components/modal/GrowthStageModal.vue')
import { logEvent, events } from '@/services/analytics'
import '@/components/GrowthStage/GrowthStageIndicator'

export default {
  name: 'SowingGrowthStage',

  components: {
    DfButton,
    GrowthStageModal,
  },

  props: {
    loading: {
      required: true,
      type: Boolean,
    },
    sowingInfos: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      isGrowthStageModalOpened: false,
      sowingId: Number(this.$route.query.sowing_id),
    }
  },

  inject: {
    getCropName: {
      default: () => '',
      from: 'getCropName',
    },
  },

  computed: {
    cropName() {
      return this.getCropName().split('.')[1]
    },
    description() {
      if (this.cropName) {
        const progressSteps =
          growthStage[this.cropName.toLowerCase()].progressSteps
        let result = []
        if (this.sowingInfos.growth_stage === 'full') {
          return this.$t('SowingView.sowing_growth_stage.full')
        } else if (!this.sowingInfos.growth_stage) {
          result = Object.keys(progressSteps)
        } else {
          result = Object.entries(progressSteps).find(([_, values]) =>
            values.includes(this.sowingInfos.growth_stage.toUpperCase())
          )
        }
        return `${this.$t('SowingView.sowing_growth_stage.stage')} ${this.$t(
          `SowingView.sowing_growth_stage.${result[0]}`
        ).toLowerCase()}`
      }
      return ''
    },
    percentage() {
      if (this.sowingInfos.growth_stage === 'full') {
        return 100
      } else if (!this.sowingInfos.growth_stage) {
        return 0
      } else {
        const index = growthStage[this.cropName.toLowerCase()].stages.findIndex(
          (stage) => stage.value === this.sowingInfos.growth_stage.toUpperCase()
        )
        return (index / growthStage[this.cropName].stages.length) * 100
      }
    },
    stageText() {
      return (
        this.sowingInfos.growth_stage ||
        growthStage[this.cropName.toLowerCase()].stages[0].value
      )
    },
  },

  methods: {
    closeGrowthStageModal() {
      this.isGrowthStageModalOpened = false
    },
    editGrowthStage() {
      logEvent(events.sowingViewModule.SowingView.edit_growth_stage)
      this.isGrowthStageModalOpened = true
    },
  },
}
</script>

<style lang="scss" scoped>
.sowing-growth-stage {
  max-height: fit-content;

  .stage__title {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
</style>
