<template>
  <div class="df-flex-l df-flex-col sowing-right-panel">
    <sowing-view-summary :loading="loading" :sowing-infos="sowingInfos" />
    <sowing-growth-stage :loading="loading" :sowing-infos="sowingInfos" />
    <sowing-field-infos :loading="loading" :sowing-infos="sowingInfos" />
  </div>
</template>

<script>
import SowingFieldInfos from '@/modules/sowingView/components/SowingFieldInfos.vue'
import SowingGrowthStage from '@/modules/sowingView/components/SowingGrowthStage.vue'
import SowingViewSummary from '@/modules/sowingView/components/SowingViewSummary.vue'

export default {
  name: 'SowingViewRightPanel',

  components: {
    SowingFieldInfos,
    SowingGrowthStage,
    SowingViewSummary,
  },

  props: {
    sowingInfos: {
      required: true,
      type: Object,
    },
  },

  computed: {
    loading() {
      return !Object.keys(this.sowingInfos).length
    },
  },
}
</script>

<style lang="scss" scoped>
.sowing-right-panel {
  width: 100%;
  background-color: #f8f9f8;
  padding: 24px;

  @include d(m) {
    padding: 24px 16px;
  }
}
</style>
