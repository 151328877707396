<template>
  <div>
    <df-page-header>
      <template #title-section>
        <df-button
          depressed
          min-width="36px"
          variant="text"
          width="36px"
          @click="goBack"
        >
          <font-awesome-icon color="#39AF49" icon="chevron-left" />
        </df-button>
        <div class="df-flex-none df-flex-col header-title">
          <h2 class="header__subtitle">{{ fieldName }}</h2>
          <h1 class="header__title">{{ title }}</h1>
        </div>
      </template>
      <template #actions>
        <df-button depressed variant="text-green" @click="openEditSowingModal">
          {{ $t('SowingView.header.edit_sowing') }}
        </df-button>
        <df-button
          data-id="add-spraying-button"
          :disabled="shouldDisableAddSpraying"
          @click="openAddSprayingModal"
        >
          {{ $t('SowingView.header.add_spraying') }}
        </df-button>
      </template>
    </df-page-header>
    <execute-sowing-modal
      v-if="isExecuteSowingModalOpened"
      editing
      :sowing-id="sowingId"
      @close="closeExecuteSowingModalOpened"
    />
    <add-spraying-modal
      v-if="isAddSprayingModalOpened"
      :crop-name="cropName.split('.')[1]"
      :sowing-id="sowingId"
      @close="closeAddSprayingModal"
    />
  </div>
</template>

<script>
const AddSprayingModal = () =>
  import('@/modules/sowingView/components/modal/AddSprayingModal.vue')
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfPageHeader from '@/lib/components/Header/DfPageHeader.vue'
import ExecuteSowingModal from '@/modules/sowingsList/components/modal/ExecuteSowingModal.vue'
import { logEvent, events } from '@/services/analytics'
import { MAX_SPRAYINGS_ALLOWED } from '@/utils/sprayings'

export default {
  name: 'SowingViewHeader',

  components: {
    AddSprayingModal,
    DfButton,
    DfPageHeader,
    ExecuteSowingModal,
  },

  props: {
    sprayings: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      isAddSprayingModalOpened: false,
      isExecuteSowingModalOpened: false,
      sowingId: Number(this.$route.query.sowing_id),
    }
  },

  inject: {
    getCropName: {
      default: () => '',
      from: 'getCropName',
    },
    getFieldName: {
      default: () => '',
      from: 'getFieldName',
    },
    getVarietyName: {
      default: () => '',
      from: 'getVarietyName',
    },
  },

  computed: {
    cropName() {
      return this.getCropName()
    },
    fieldName() {
      return this.getFieldName()
    },
    shouldDisableAddSpraying() {
      return !!(
        this.sprayings.length &&
        this.sprayings.length >=
          MAX_SPRAYINGS_ALLOWED[this.sprayings[0].type][this.cropName]
      )
    },
    title() {
      return this.translatedCropName + ' | ' + this.varietyName
    },
    translatedCropName() {
      return this.$t(this.cropName)
    },
    varietyName() {
      return this.getVarietyName()
    },
  },

  methods: {
    closeAddSprayingModal() {
      this.isAddSprayingModalOpened = false
    },
    closeExecuteSowingModalOpened() {
      this.isExecuteSowingModalOpened = false
    },
    goBack() {
      this.$router.push({ path: '/sowings_list' })
    },
    openAddSprayingModal() {
      logEvent(events.sowingViewModule.SowingView.create_spraying)
      this.isAddSprayingModalOpened = true
    },
    openEditSowingModal() {
      logEvent(events.sowingViewModule.SowingView.edit_sowing)
      this.isExecuteSowingModalOpened = true
    },
  },
}
</script>

<style lang="scss" scoped>
.header-title {
  .header__subtitle {
    color: #5b6459;
    font-family: Rubik;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .header__title {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
  }
}
</style>
