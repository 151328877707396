<template>
  <div
    class="df-flex-l df-flex-m-t df-flex-sm-m df-flex-col-m spraying-list-item"
  >
    <div class="df-col-2 df-col-12-m">
      <p v-if="sprayingStatusName === 'done'" class="mb-2 item__date">
        {{ $t('SowingView.spraying_list_item.registered_on') }}
      </p>
      <p class="item__date">{{ date }}</p>
      <p v-if="showDaysLate" class="mt-2 item__days-late">
        {{ daysLate }} {{ $t('SowingView.spraying_list_item.days_late') }}
      </p>
    </div>
    <div class="df-col-2 df-col-3-t df-col-12-m">
      <div class="df-flex-l align-center item__type">
        <div class="df-flex-none align-center justify-center type__icon">
          <font-awesome-icon color="#5B6459" icon="vial" />
        </div>
        <div
          class="df-flex-xm df-flex-m-m df-flex-col-d df-flex-col-t type__content"
        >
          <div>
            <span class="type__text">
              {{ $t(`SowingView.spraying_list_item.${spraying.type}`) }}
            </span>
            <span class="type__text"> ({{ spraying.spraying_order }}º)</span>
          </div>
          <df-chip v-if="showSprayingStatus" :type="sprayingStatus">
            {{ $t(`SowingView.spraying_list_item.${sprayingStatusName}`) }}
          </df-chip>
        </div>
      </div>
    </div>
    <div class="df-col-2 item__price">
      <span>{{ $currency(price) }}</span>
    </div>
    <div class="df-col-3 item__products">
      <div class="df-flex-sm df-flex-col">
        <span v-for="(product, index) in products" :key="index">
          {{ product }}
        </span>
      </div>
    </div>
    <div class="df-col-3 df-col-4-t df-col-12-m">
      <div class="df-flex-sm df-flex-l-m item__actions">
        <df-button
          v-if="showRegisterButton"
          class="item__register"
          variant="secondary"
          @click="openRegisterSprayingModal"
        >
          <p>{{ $t('SowingView.spraying_list_item.register') }}</p>
        </df-button>
        <df-button
          v-else
          class="item__register"
          variant="secondary"
          @click="openEditRegistrySprayingModal"
        >
          <p>{{ $t('SowingView.spraying_list_item.edit_registry') }}</p>
        </df-button>
        <df-button
          min-width="36px"
          variant="secondary"
          width="36px"
          @click="openEfficacyModal"
        >
          <font-awesome-icon icon="chart-bar" />
        </df-button>
        <df-dropdown-list icon="ellipsis-v" :items="sowingActions" />
      </div>
    </div>
    <add-spraying-modal
      v-if="isEditSprayingModalOpened"
      :crop-name="cropName"
      :edited-spraying="spraying"
      :sowing-id="Number($route.query.sowing_id)"
      @close="closeEditSprayingModal"
    />
    <register-spraying-modal
      v-if="isRegisterSprayingModalOpened"
      :spraying="spraying"
      @close="closeRegisterSprayingModal"
    />
    <spraying-efficacy-modal
      v-if="isEfficacyModalOpened"
      :products="products"
      :spraying-id="spraying.id"
      @close="closeEfficacyModal"
    />
    <df-delete-modal
      v-if="isDeleteSprayingModalOpened"
      :text="$t('SowingView.delete_modal.text')"
      :title="$t('SowingView.delete_modal.title')"
      @action-click="deleteSpraying"
      @close="closeDeleteSprayingModal"
    />
  </div>
</template>

<script>
const AddSprayingModal = () =>
  import('@/modules/sowingView/components/modal/AddSprayingModal.vue')
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfChip from '@/lib/components/Chip/DfChip.vue'
const DfDeleteModal = () => import('@/lib/Modal/DfDeleteModal.vue')
import DfDropdownList from '@/lib/components/List/Dropdown/DfDropdownList.vue'
import moment from 'moment'
const RegisterSprayingModal = () =>
  import('@/modules/sowingView/components/modal/RegisterSprayingModal.vue')
import sowingViewService from '@/modules/sowingView/services/sowingViewService.js'
const SprayingEfficacyModal = () =>
  import('@/modules/sowingView/components/modal/SprayingEfficacyModal.vue')
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'SprayingListItem',

  components: {
    AddSprayingModal,
    DfButton,
    DfChip,
    DfDeleteModal,
    DfDropdownList,
    RegisterSprayingModal,
    SprayingEfficacyModal,
  },

  props: {
    spraying: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      isDeleteSprayingModalOpened: false,
      isEditSprayingModalOpened: false,
      isEfficacyModalOpened: false,
      isRegisterSprayingModalOpened: false,
    }
  },

  inject: {
    getCropName: {
      default: () => '',
      from: 'getCropName',
    },
    requestSowingSprayings: {
      from: 'requestSowingSprayings',
    },
  },

  computed: {
    cropName() {
      return this.getCropName()
    },
    date() {
      return this.spraying.applied_date
        ? this.spraying.applied_date.formatDate()
        : this.spraying.ideal_date.formatDate()
    },
    daysLate() {
      return moment().diff(moment(this.spraying.ideal_date), 'days')
    },
    price() {
      return this.spraying.products.reduce((acc, product) => {
        acc += product.dose_per_hectare * product.price_per_liter
        return acc
      }, 0)
    },
    products() {
      return this.spraying.products.reduce((acc, product) => {
        acc.push(
          `${
            product.name.charAt(0).toUpperCase() +
            product.name.slice(1).toLowerCase()
          } • ${product.dose_per_hectare}L/ha`
        )
        return acc
      }, [])
    },
    showDaysLate() {
      return this.daysLate > 0
    },
    showRegisterButton() {
      return this.sprayingStatusName !== 'done'
    },
    showSprayingStatus() {
      return (
        !!this.spraying.applied_date ||
        new Date(this.spraying.ideal_date) < new Date()
      )
    },
    sowingActions() {
      let registerAction = {
        name: this.$t('SowingView.spraying_list_item.edit_registry'),
        icon: 'save',
        action: () => this.openEditRegistrySprayingModal(),
      }
      if (this.showRegisterButton) {
        registerAction = {
          name: this.$t('SowingView.spraying_list_item.register'),
          icon: 'save',
          action: () => this.openRegisterSprayingModal(),
        }
      }
      return [
        {
          name: this.$t('SowingView.spraying_list_item.edit'),
          icon: 'edit',
          action: () => {
            logEvent(events.sowingViewModule.SowingView.edit_spraying)
            this.isEditSprayingModalOpened = true
          },
        },
        registerAction,
        {
          name: this.$t('SowingView.spraying_list_item.spraying_effectiveness'),
          icon: 'chart-bar',
          action: () => this.openEfficacyModal(),
        },
        {
          name: this.$t('SowingView.spraying_list_item.delete'),
          color: '#F23528',
          icon: 'trash',
          action: () => {
            logEvent(events.sowingViewModule.SowingView.delete_spraying)
            this.isDeleteSprayingModalOpened = true
          },
        },
      ]
    },
    sprayingStatus() {
      if (this.spraying.applied_date) return 'success'
      else if (new Date(this.spraying.ideal_date) < new Date()) return 'error'
      else return ''
    },
    sprayingStatusName() {
      if (this.spraying.applied_date) return 'done'
      else if (new Date(this.spraying.ideal_date) < new Date()) return 'late'
      else return ''
    },
  },

  methods: {
    closeDeleteSprayingModal() {
      this.isDeleteSprayingModalOpened = false
    },
    closeEditSprayingModal() {
      this.isEditSprayingModalOpened = false
    },
    closeEfficacyModal() {
      this.isEfficacyModalOpened = false
    },
    closeRegisterSprayingModal() {
      this.isRegisterSprayingModalOpened = false
    },
    async deleteSpraying() {
      const params = {
        spraying_id: this.spraying.id,
      }
      try {
        await sowingViewService.deleteSpraying(params)
        this.requestSowingSprayings()
      } catch (error) {
        console.error(error)
      }
    },
    openEditRegistrySprayingModal() {
      logEvent(events.sowingViewModule.SowingView.edit_registry)
      this.isRegisterSprayingModalOpened = true
    },
    openEfficacyModal() {
      logEvent(events.sowingViewModule.SowingView.view_efficacy)
      this.isEfficacyModalOpened = true
    },
    openRegisterSprayingModal() {
      logEvent(events.sowingViewModule.SowingView.register_spraying)
      this.isRegisterSprayingModalOpened = true
    },
  },
}
</script>

<style lang="scss" scoped>
.spraying-list-item {
  padding: 8px 0px 8px 0px;
  align-items: center;

  @include d(m) {
    padding: 16px;
    align-items: stretch;
  }

  .item__date,
  .item__price span {
    color: #5b6459;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .item__days-late {
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  .item__price {
    @include d(t) {
      display: none;
    }
    @include d(m) {
      display: none;
    }
  }
  .item__type {
    flex-wrap: nowrap;

    .type__icon {
      background-color: #f8f9f8;
      border-radius: 4px;
      height: 28px;
      max-width: 28px;
      min-width: 28px;

      svg {
        width: 12px;
      }
      @include d(m) {
        display: none;
      }
    }
    .type__content {
      @include d(m) {
        align-items: center;
      }
      .type__text {
        color: #1a2b46;
        font-family: 'Rubik';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
  .item__products {
    @include d(m) {
      display: none;
    }
    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .item__actions {
    justify-content: end;
    flex-wrap: wrap;

    @include d(m) {
      justify-content: stretch;
      margin-top: 8px;
    }
    .item__register {
      @include d(m) {
        flex-grow: 1;
      }
      p {
        color: #1a2b46;
      }
    }
  }
}
</style>
